import { Button } from "react-bootstrap";


function ProtectedSite({setToken}){

    const handleLogoutClick = () => {
        setToken(false);
    }

    return(
        <>ProtectedSite

            <Button variant="warning" onClick={handleLogoutClick}>Logout</Button>
        </>
    );
};


export default ProtectedSite;
// React imports 


// Component imports
import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";



function Layout({token, setToken}) {
    return (
        <>
            <Header token={token} setToken={setToken} ></Header>
            <Main>
            </Main>
            <Footer></Footer>
        </>
    );
}

export default Layout;
import React from 'react';
import { Button, Row, Col, Container } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

//CSS imports
import "./../general/Main.css";


function BackButtonContainer() {

  const navigate = useNavigate(); 

  const goBack = () => {
    navigate(-1); // Go back one step in history
  };

  return (
    <div className="d-flex justify-content-start mb-3">
      <Button size="sm" onClick={goBack}>Back</Button>
    </div>
  );
}


export default BackButtonContainer;

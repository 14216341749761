// React imports
import React, { createContext, useState, useEffect } from 'react';

// Components import
import LoadingAnimation from './views/overview/LoadingAnimation.js';
import ErrorModal from './general/ErrorModal.js';

// Data Class imports
import AASData from './AasData.js';




export const AasDataContext = createContext();

export const AasDataProvider = ({ children }) => {
  const [allAas, setAllAas] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchAllAas() {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/aas`);
        const data = await response.json();
        const aasData = data.map(item => new AASData(item));
        setAllAas(aasData);
      } catch (error) {
        setError(error);
        console.error('Error fetching aas data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchAllAas();
  }, []);


  const updateAasData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/aas`);
      const data = await response.json();
      const aasData = data.map(item => new AASData(item));
      setAllAas(aasData);
    } catch (error) {
      setError(error);
      console.error('Error updating aas data:', error);
    }
  }


  if (loading) {
    return <LoadingAnimation />;
  }

  if (error) {
    return <ErrorModal />;
  }

  return (
    <AasDataContext.Provider value={{ allAas, loading, updateAasData}}>
      {children}
    </AasDataContext.Provider>
  );


};

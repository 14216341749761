// React imports
import Accordion from 'react-bootstrap/Accordion';

function AccordionBuilder({accordionBodys, styles}) {
  return (
    <Accordion className={styles}>
      {accordionBodys.map((item, index) => (
        <Accordion.Item key={index} eventKey={index.toString()}>
          <Accordion.Header>{item.header}</Accordion.Header>
          <Accordion.Body>
            {item.body}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}

export default AccordionBuilder;

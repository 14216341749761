// React Imports
import React from 'react';
import { Container } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

//CSS Imports
import './LoadingAnimation.css';




function LoadingAnimation() {
    return (<>
        <div className="overlay"></div>
        <div className="loader-container">
            <div className="loader"></div>
        </div>
    </>


    );
}

export default LoadingAnimation;

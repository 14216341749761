// React and React Bootstrap imports
import React from 'react';
import { Container } from 'react-bootstrap';



function Welcome() {
  return (
    <Container id='welcomeDiv' className="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
      <input type="image" src="press_to_start.svg" alt="Submit" style={{ width: '100%', maxWidth:'400px' }} onClick={()=>window.location.href="/aas"}/>
    </Container>
  );
}

export default Welcome;


// react imports
import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";

// CSS imports
import './Main.css';




function Main() {
    return (
        <main>
            <Container className="flex-grow-1 d-flex flex-column p-0" fluid>
                <Container id="alert-container"></Container>
                <Outlet></Outlet>
            </Container>
        </main>
    );
}

export default Main;


// react imports
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';

// Image imports
import aasLogo from '../../assets/images/aas_logo.png';
import cliceDippLogo from '../../assets/images/clice_dipp_logo.png'

//CSS import
import './Header.css';
import useToken from '../../helper/useToken';
import { useEffect } from 'react';

function Header({ token, setToken }) {

    const handleLogoutClick = () => {
        setToken(null);
        sessionStorage.removeItem('token');
    }



    return (
        <Navbar className="navbar-custom" fixed='top'>
            <Container>

                <Navbar.Brand className='navbar-brand-custom' href='/'>
                    <img src={cliceDippLogo} alt="Logo" style={{ maxHeight: '80px' }} className='logo-height' />
                </Navbar.Brand>

                <Nav className='ml-auto'>
                    <Nav.Link className='nav-link-custom' href='/aas'>Overview</Nav.Link>
                    {token == 'softwareag' &&
                        <Nav.Link className='nav-link-custom' href='/admin'>Admin</Nav.Link>
                    }

                    {token ?
                        <Nav.Link className='nav-link-custom' onClick={handleLogoutClick}>Logout</Nav.Link> :
                        <Nav.Link className='nav-link-custom' href='/login'>Login</Nav.Link>
                    }


                </Nav>

            </Container>
        </Navbar>
    );
}

export default Header;
